/* .App{
    background: linear-gradient(
        106.37deg,
        #ffe1bc 29.63%,
        #ffcfd1 51.55%,
        #f3c6f1 90.85%
    );
  
    
} */
.Card{
    background: linear-gradient(180deg, #FF919D 0%, #FC929D 100%);
    box-shadow: 0px 10px 20px 0px #FDC0C7;
   
}
.header {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    color: #333;
    padding: 2px;
    background-color: #f5f5f5;
    display: flex;
    align-items: center;
    background: transparent;
    margin-bottom: 10px;
  }
  
  .section {
    font-weight: 600;
    color: #666;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  
  .current {
    color: #ff6b6b;
  }
  
  .arrow {
    margin: 0 12px;
    color: #999;
    font-weight: 600;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
  }
  
  .section:hover, .current {
    color: #333;
  }
  
  .arrow:hover {
    color: #666;
    transform: scale(1.2);
  }
  .header span:last-child {
    color: #333;
  }
  .arrow_right{
    width: 25px;
    height: 25px;
    text-align: end;
    cursor: pointer;
    margin-right: 20px;
  }
  .arrow_right:hover{
    
    transform: scale(1.3)
  }